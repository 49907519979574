import React from 'react';
import {Route, Routes} from "react-router-dom";

import StudentsCurrentV2 from "./StudentsCurrentV2";
import StudentsV2 from "./StudentsV2";
import StudentDetailV2 from "./StudentDetailV2";
import MyPlupusV2 from "./MyPlupusV2";

function PlupusRouter() {

    return (
        <Routes>
            {/*TODO remove these files*/}
            {/*<Route path="students" element={<Students/>}/>*/}
            {/*<Route path="students/:studentId" element={<StudentDetail/>}/>*/}
            {/*<Route path="my" element={<MyPlupus/>}/>*/}

            {/*  V2 */}
            <Route path="v2/students/current" element={<StudentsCurrentV2/>}/>
            <Route path="v2/students" element={<StudentsV2/>}/>
            <Route path="v2/students/:studentId" element={<StudentDetailV2/>}/>
            <Route path="v2/my" element={<MyPlupusV2/>}/>

        </Routes>
    )
}


export default function Plupus() {
    return <PlupusRouter/>
}
