import React, {useContext, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {
    changeStatus,
    createMessage,
    deleteMessage,
    emptyTrash,
    getArchived,
    getConversationById,
    getDrafted,
    getInbox,
    getMessengerPermissions,
    getMessgRecepients,
    getRecipientsByFilter,
    getSent,
    getTrashed,
    getUnreadMessages,
    readConversation,
    sendMessageAPI,
    updateMessage,
    uploadFileForMessage,
} from "../api/messenger";
import {useIsNarrower} from "../utils/useIsNarrower";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {ArchiveBoxIcon, InboxIcon, PaperAirplaneIcon, PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {useAuth} from "./AuthContext";

const MessengerContext = React.createContext();

export function useMessenger() {
    return useContext(MessengerContext);
}

let timerID

const initMessageData = {
    message: "",
    subject: "",
    draft_recipients: [],
    attachments: [],
    save_attachments_in_downloads: false
}

export const sideBarTabsList = [
    {name: "Nachrichten", value: "inbox", icon: InboxIcon},
    {name: "Archiv", value: "archived", icon: ArchiveBoxIcon},
    {name: "Gesendet", value: "sent", icon: PaperAirplaneIcon},
    {name: "Papierkorb", value: "trash", icon: TrashIcon},
    {name: "Entwürfe", value: "draft", icon: PencilSquareIcon},
];

export function MessengerProvider({children}) {

    const {currentUser, currentUserId} = useAuth()
    const navigate = useNavigate()
    const isMobile = useIsNarrower(1024)

    const [page, setPage] = useState(1)

    const [inboxTotalCount, setInboxTotalCount] = useState(0)
    const [archivedTotalCount, setArchivedTotalCount] = useState(0)
    const [trashedTotalCount, setTrashedTotalCount] = useState(0)
    const [draftsTotalCount, setDraftsTotalCount] = useState(0)
    const [sentTotalCount, setSentTotalCount] = useState(0)

    const [newMessagesCount, setNewMessagesCount] = useState(0)
    const [isOpenMessenger, setIsOpenMessenger] = useState(false)
    const [lastUpdatedAt, setLastUpdatedAt] = useState(0)

    const [inboxData, setInboxData] = useState()
    const [archivedData, setArchivedData] = useState()
    const [trashedData, setTrashedData] = useState()
    const [draftsData, setDraftsData] = useState()
    const [sentData, setSentData] = useState()

    const [currentTab, setCurrentTab] = useState("Nachrichten");
    const [conversationList, setConversationList] = useState(undefined);
    const [selectedConversationData, setSelectedConversationData] = useState(undefined);

    const [isLoadedConversationData, setIsLoadedConversationData] = useState(false);

    const [selectedConversationId, setSelectedConversationId] = useState(undefined);
    const [tempConversationId, setTempConversationId] = useState(undefined);

    const [isNewConversation, setIsNewConversation] = useState(false)
    const [preloadUserRecipient, setPreloadUserRecipient] = useState(undefined)

    const [messageData, setMessageData] = useState(initMessageData)

    const [filesToSend, setFilesToSend] = useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [availableRecipients, setAvailableRecipients] = useState([]);

    const [searchLoading, setSearchLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [replyOn, setReplyOn] = useState(false);

    const [isFileAttachments, setIsFileAttachments] = useState(false)
    const [isMessageMultipleRecipients, setIsMessageMultipleRecipients] = useState(false)

    const loggedIn = currentUserId ? true : false;

    const {data: recipients, loading} = useQuery(
        ["message_recipient"],
        () => getMessgRecepients(),
        {
            enabled: loggedIn, // currentTab === "Entwürfe" &&
            refetchInterval: 1000 * 60 * 60 * 24 // refetch every 24 hours - will rarely change
        }
    );
    const {data: permissions} = useQuery(
        ["message_permissions"],
        () => getMessengerPermissions(),
        {
            enabled: loggedIn, //(currentTab === "Entwürfe")
            refetchInterval: 1000 * 60 * 60 * 24// refetch every 24 hours
        }
    );
    const unread_messages = useQuery(["unread_messages", currentUserId], getUnreadMessages, {
        enabled: loggedIn, refetchInterval: 1000 * 60 * 10
    });
    const inbox = useQuery(["inbox", currentUserId], () => getInbox(page), {
        enabled: currentTab === "Nachrichten" && loggedIn, refetchInterval: 1000 * 60 * 10
    });
    const archived = useQuery(["archived", currentUserId], () => getArchived(page), {
        enabled: currentTab === "Archiv" && loggedIn, refetchInterval: 1000 * 60 * 10
    });
    const sent = useQuery(["sent", currentUserId], () => getSent(page), {
        enabled: currentTab === "Gesendet" && loggedIn, refetchInterval: 1000 * 60 * 10
    });
    const trashed = useQuery(["trashed", currentUserId], () => getTrashed(page), {
        enabled: currentTab === "Papierkorb" && loggedIn,  refetchInterval: 1000 * 60 * 10
    });
    const drafts = useQuery(["drafts", currentUserId], () => getDrafted(page), {
        enabled: currentTab === "Entwürfe" && loggedIn, refetchInterval: 1000 * 60 * 10
    });
    const conversationAPI = useQuery(["conversation", currentUserId, selectedConversationId],
        () => selectedConversationId && getConversationById(selectedConversationId), {
            enabled: loggedIn,
            refetchInterval: 1000 * 60 * 15
        }
    );

    const fetchRecipientsByFilter = (payload) => {
        setSearchLoading(true);
        getRecipientsByFilter(payload)
            .then((res) => {
                setSelectedRecipients([
                    ...new Set([...selectedRecipients, ...res.map((i) => i.id)]),
                ]);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setSearchLoading(false);
            });
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        await uploadFileForMessage(formData, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((res) => {
                setFilesToSend([...filesToSend, res]);
                setMessageData({
                    ...messageData,
                    attachments: [...messageData.attachments, {id: res.id}],
                });
            })
            .catch((err) => console.log(err));
    };

    const doSaveMessage = async () => {
        const payload = {
            id: messageData.id,
            parent_message_id: selectedConversationId,
            sender_status: 'draft',
            // draft_recipients: selectedRecipients,
            draft_recipients: messageData.draft_recipients,
            subject: messageData.subject,
            message: messageData.message,
            attachments: filesToSend,
            save_attachments_in_downloads: messageData.save_attachments_in_downloads
        }

        let message
        try {
            if (messageData.id) {
                message = await updateMessage(messageData.id, payload)
            } else {
                message = await createMessage(payload)
                setSelectedConversationId(message.parent_message_id)
            }
            return message
        } catch (err) {
            console.log(err);
            toast.error(
                err.data.message
                    ? err.data.message.toString()
                    : "Nachricht konnte nicht gespeichert werden"
            );
        }
    }

    const saveDraft = async () => {

        const message = await doSaveMessage()
        setMessageData(message)

        if (currentTab === "Entwürfe") {
            drafts.refetch()
        } else {
            conversationAPI.refetch()
        }
    }

    const sendMessage = async () => {

        // save first
        const message = await doSaveMessage()
        setMessageData(message)

        // send
        const sentMessage = await sendMessageAPI(message.id)

        // update, switch etc. as needed
        if (currentTab === "Entwürfe") {
            // go to sent tab and set conversation id
            setSelectedConversationId(sentMessage.parent_message_id)
            setCurrentTab('Gesendet')
            setSelectedRecipients([])
        } else {
            setReplyOn(false)
            conversationAPI.refetch()
        }
        resetData()
    }

    const deleteDraft = async (messageId) => {

        await deleteMessage(messageId)

        if (currentTab === "Entwürfe") {
            resetData()
            drafts.refetch()
            setSelectedConversationId(-1)
        } else {
            conversationAPI.refetch()
        }
    }

    const startEditDraft = (item) => {
        setReplyOn(true)
        setMessageData(item)
        setFilesToSend(item.attachments)
    }

    const resetData = () => {
        setMessageData(initMessageData);
        setSelectedRecipients([]);
        setFilesToSend([]);
    }

    const clearData = () => {
        setIsOpenMessenger(false)
        setArchivedData(undefined)
        setTrashedData(undefined)
        setDraftsData(undefined)
        setSentData(undefined)
        // setCurrentTab(undefined)
        setConversationList(undefined)
        setSelectedConversationData(undefined)
        setIsLoadedConversationData(false)
        setSelectedConversationId(undefined)
        setTempConversationId(undefined)
        setIsNewConversation(false)
        setPreloadUserRecipient(undefined)
        // setCurrentMessageData(initCurrentMessageData)
        // setMessageFormData(initMessageFormData)
        setMessageData(initMessageData)
        setFilesToSend([])
        setSelectedRecipients([])
        setSearchLoading(false)
        setProgress(0)
        setReplyOn(false)
        // setIsFileAttachments(false)
        // setIsMessageMultipleRecipients(false)
        // setIsStartGroupMessages(false)
    }

    const handleNewConversation = () => {
        resetData()
        setSelectedConversationId(-1)
        navigate('/messenger/new')
    }

    const handleEmptyTrash =  async () => {
        await emptyTrash()
        trashed.refetch()
        setSelectedConversationId(undefined)
        setSelectedConversationData(undefined)
    }

    const changeMessageData = (e) => {
        if (e.currentTarget && e.currentTarget.type === 'checkbox') {
            setMessageData({
                ...messageData,
                [e.target.name]: e.currentTarget.checked
            });
        } else {
            setMessageData({
                ...messageData,
                [e.target.name]: e.target.value,
            });
        }
    };

    function handleSingleSelectRecipients(selectedOption, path) {
        let newData = {...messageData};
        newData[path] = [selectedOption.target.value];
        setMessageData(newData);
        if (selectedRecipients !== [selectedOption.target.value])
            setSelectedRecipients([selectedOption.target.value]);
    }

    function handleMultiSelectRecipients(selectedOptions, path) {
        let newData = {...messageData};
        newData[path] = selectedOptions;
        setMessageData(newData);
        if (selectedRecipients !== selectedOptions)
            setSelectedRecipients(selectedOptions);
    }

    const changeAvailableRecipients = () => {
        const newRecipients = permissions.users.map((user) => {
            return {
                value: user.id,
                label: user.full_name,
            };
        });
        setAvailableRecipients(newRecipients);
    }

    const changePermissions = () => {
        setIsFileAttachments(permissions.can_send_attachments)
        setIsMessageMultipleRecipients(permissions.can_message_multiple_recipients)
    }

    const changeCurrentTab = (tab) => {
        setIsLoadedConversationData(true)
        setSelectedConversationId(undefined)
        setCurrentTab(tab)
        setPage(1)
        resetData()
    }

    const changeTempConversationId = (conversationId, user_id) => {
        if (conversationId === 'new') {
            setCurrentTab("Entwürfe")
            setIsNewConversation(true)
            user_id && typeof (parseInt(user_id)) === 'number' && setPreloadUserRecipient(parseInt(user_id))
        } else {
            conversationId && typeof (parseInt(conversationId)) === 'number' && setTempConversationId(parseInt(conversationId))
        }
    }

    const changeConversationStatus = (status) => {
        changeStatus(selectedConversationId, status)
            .then(() => refetchConversationsList());
    };

    const changeSelectedConversationId = (item, isAvailableSelectConversation) => {
        if (currentTab === "Entwürfe") {
            if (isNewConversation) {
                const userRecipient = availableRecipients.find(item => item.value === preloadUserRecipient)
                if (userRecipient) {
                    setMessageData({...messageData, draft_recipients: [userRecipient.value]});
                    setSelectedRecipients([userRecipient.value])
                } else {
                    setPreloadUserRecipient(undefined)
                }
                setSelectedConversationId(-1)
            } else {
                setMessageData(item)
                setSelectedRecipients(item.draft_recipients);
                setFilesToSend(item.attachments || []);
                if (isAvailableSelectConversation) {
                    setSelectedConversationId(item.parent_message_id)
                    setIsLoadedConversationData(true)
                }
            }
        } else {
            if (isAvailableSelectConversation) {
                setSelectedConversationId(item.parent_message_id)
                setIsLoadedConversationData(true)
                resetData() // TODO not sure if this is enough
            }
        }
    }

    //0
    const refetchConversationsList = () => {
        clearTimeout(timerID)

        if (isOpenMessenger) {
            switch (currentTab) {
                case "Archiv":
                    archived.refetch();
                    break;
                case "Papierkorb":
                    trashed.refetch()
                    break;
                case "Entwürfe":
                    drafts.refetch();
                    break;
                case "Nachrichten":
                    inbox.refetch();
                    break;
                case "Gesendet":
                    sent.refetch();
                    break;
                default:
                    break;
            }

            if (selectedConversationId > 0) {
                conversationAPI.refetch();
            }
        }

        setLastUpdatedAt(new Date().getTime())
    };

    //1
    const loadSelectedConversationData = async () => {
        try {
            const data = await getConversationById(tempConversationId)
            setSelectedConversationData(data)
        } catch (e) {
            setCurrentTab("Nachrichten")
        }
    }

    //3
    const selectConversationList = () => {
        switch (currentTab) {
            case "Nachrichten":
                setConversationList(inboxData);
                break;
            case "Archiv":
                setConversationList(archivedData);
                break;
            case "Papierkorb":
                setConversationList(trashedData);
                break;
            case "Entwürfe":
                setConversationList(draftsData);
                break;
            case "Gesendet":
                setConversationList(sentData);
                break;
            default:
                break;
        }
        refetchConversationsList()
    }

    const totalCount = () => {
        switch (currentTab) {
            case "Nachrichten":
                return inboxTotalCount;
                break;
            case "Archiv":
                return archivedTotalCount;
                break;
            case "Papierkorb":
                return trashedTotalCount;
                break;
            case "Entwürfe":
                return draftsTotalCount;
                break;
            case "Gesendet":
                return sentTotalCount;
                break;
            default:
                break;
        }
        refetchConversationsList()
    }

    //4
    const selectConversationId = () => {
        if (tempConversationId) {
            setSelectedConversationId(tempConversationId)
        } else {
            if (!!conversationList.length && !selectedConversationId) {
                changeSelectedConversationId(conversationList[0], !isMobile)
            }
        }
        setTempConversationId(undefined)
    }

    //50
    const updateSelectedConversationData = async () => {
        if (selectedConversationId > 0) {
            let res = await conversationAPI.refetch()
            setSelectedConversationData(res.data)
            setIsLoadedConversationData(false)

            const hasUnreadMessages = res.data && res.data.some(message => !message.read_at && message.recipient?.id === currentUser.user_id)
            if (hasUnreadMessages) {
                res = await readConversation(selectedConversationId)
                unread_messages.refetch()
                // setSelectedConversationData(res.data)
                refetchConversationsList()
            }
        }
    }

    //51
    const changeUrlPath = () => {
        navigate(selectedConversationId > 0
            ? `/messenger/${selectedConversationId}`
            : (preloadUserRecipient
                ? `/messenger/new?user_id=${preloadUserRecipient}`
                : `/messenger/new`)
        )
    }

    //52
    const resetSearchData = () => {
        setPreloadUserRecipient(undefined)
        setIsNewConversation(false)
    }

    useEffect(() => {
        if (permissions) {
            changeAvailableRecipients()
            changePermissions()
        }
    }, [permissions]);

    //0
    useEffect(() => {
        timerID = setTimeout(() => {
            refetchConversationsList()
        }, isOpenMessenger ? 180000 : 180000) // 180000 = 3 min
    }, [isOpenMessenger, lastUpdatedAt])

    useEffect(() => {
        if (unread_messages.data) {
            setNewMessagesCount(unread_messages.data.unread_messages)
        }
    }, [unread_messages])

    //01
    useEffect(() => {
        if (inbox.data) {
            setInboxData(inbox.data.results)
            setInboxTotalCount(inbox.data.count)
        }
    }, [inbox])

    useEffect(() => {
        if (isOpenMessenger && archived.data) {
            setArchivedData(archived.data.results)
            setArchivedTotalCount(archived.data.count)
        }
    }, [archived])

    useEffect(() => {
        if (isOpenMessenger && trashed.data) {
            setTrashedData(trashed.data.results)
            setTrashedTotalCount(trashed.data.count)
        }
    }, [trashed])

    useEffect(() => {
        if (isOpenMessenger && drafts.data) {
            setDraftsData(drafts.data.results)
            setDraftsTotalCount(drafts.data.count)
        }
    }, [drafts])
    useEffect(() => {
        if (isOpenMessenger && sent.data) {
            setSentData(sent.data.results)
            setSentTotalCount(sent.data.count)
        }
    }, [sent])

    useEffect(() => {
        isOpenMessenger && conversationAPI.data && setSelectedConversationData(conversationAPI.data)
    }, [conversationAPI])

    //1
    useEffect(() => {
        if (isOpenMessenger && !selectedConversationData) {
            if (tempConversationId) {
                loadSelectedConversationData()
            } else {
                setCurrentTab(currentTab ? currentTab : "Nachrichten")
            }
        }
    }, [isOpenMessenger, tempConversationId, inboxData, archivedData, trashedData, sentData, draftsData])

    //2
    // useEffect(() => {
    //     if (isOpenMessenger && selectedConversationData && !currentTab) {
    //         selectCurrentTab()
    //     }
    // }, [isOpenMessenger, selectedConversationData])

    //3
    useEffect(() => {
        if (isOpenMessenger && currentTab) {
            selectConversationList()
        }
    }, [isOpenMessenger, currentTab, inboxData, archivedData, trashedData, draftsData, sentData, page])

    //4
    useEffect(() => {
        if (isOpenMessenger && conversationList) {
            selectConversationId()
        }
    }, [isOpenMessenger, conversationList])

    //5
    useEffect(() => {
        if (isOpenMessenger && selectedConversationId) {
            updateSelectedConversationData()
            changeUrlPath()
            resetSearchData()
        }
    }, [isOpenMessenger, selectedConversationId])


    const value = {
        newMessagesCount,

        isOpenMessenger,
        setIsOpenMessenger,

        currentTab,
        changeCurrentTab,

        conversationList,

        selectedConversationData,

        selectedConversationId,

        selectConversationList,

        changeTempConversationId,

        messageData,
        changeMessageData,
        setMessageData,

        // currentMessageData,
        // changeCurrentMessageData,
        //
        // messageFormData,
        // changeMessageFormData,
        // setMessageFormData,

        filesToSend,
        setFilesToSend,

        selectedRecipients,
        setSelectedRecipients,

        availableRecipients,

        searchLoading,
        progress,

        replyOn,
        setReplyOn,

        isFileAttachments,
        isMessageMultipleRecipients,

        recipients,
        permissions,

        resetData,
        clearData,
        handleNewConversation,
        handleEmptyTrash,
        fetchRecipientsByFilter,
        uploadFile,

        saveDraft,
        sendMessage,
        deleteDraft,

        // performMessageDelete,
        startEditDraft,
        // saveDraftInDetail,
        // saveDraftInNew,
        // sendMessageInDetail,
        // sendMessageInNew,

        changeConversationStatus,
        changeSelectedConversationId,

        page,
        setPage,
        totalCount,

        handleSingleSelectRecipients,
        handleMultiSelectRecipients,

        isLoadedConversationData,
    };

    return (
        <MessengerContext.Provider value={value}>
            {!loading && children}
        </MessengerContext.Provider>
    );
}
