import {Link, useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import {DownloadButton, EditButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TwoLineColumn, TwoLineColumnWithLink} from "../../elements/tables/ListViewTable";
import {getCourse, getCourseMaterialsAndHomework, getStudentListExcel} from "../../../api/courses";
import {DetailPage} from "../../elements/Pages";
import {useUserInfo} from "../../../contexts/UserInfoContext";
import {renderRating} from "../../../utils/helpers";

export default function CourseDetail() {

    const {id} = useParams();
    const {permissions} = useAuth();
    const {renderUserImage} = useUserInfo()

    const {data: course} = useQuery(
        ["course_detail", id],
        () => getCourse(id)
    )

    const {data: materialsAndHomework} = useQuery(["course_materials_and_homework", id], () =>
        getCourseMaterialsAndHomework(id)
    )

    const canViewStudentsMini = checkPermission(permissions, "school.view_student_mini")
    const canViewStudentsBasic = checkPermission(permissions, "school.view_student_basic")
    const canViewCourseTopics = checkPermission(permissions, "school.view_coursetopics")

    if (!course) return <></>;

    const canViewGrades = course.can_create_class_register_entry // sic!

    return (
        <DetailPage>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{course.course_number} {course.school_subject.name}</h1>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    {canViewCourseTopics && <SecondaryButton
                        link={`/courses/${id}/course-topics`}
                        label="Kursthemen"
                    />}
                    {canViewGrades && <SecondaryButton
                        link={`/grades/courses/${id}`}
                        label="Noten"
                    />}
                    {canViewGrades && <SecondaryButton
                        link={`/grades/courses/${id}/notenblatt`}
                        label="Notenblatt"
                    />}
                    <PrimaryButton
                        link={`/class-register/courses/${id}`}
                        label="Kursbuch"
                    />

                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">

                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Schuljahr</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{course.school_year.name}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">LehrerIn</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                                            {course.teacher && <Link to={`/staff/${course.teacher.id}`}>
                                                <li className="flex ">
                                                    {renderUserImage(course.teacher.user_id)}
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-gray-900 py-2">
                                                            {course.teacher.full_name}
                                                        </p>
                                                    </div>
                                                </li>
                                            </Link>}

                                            {course.additional_teachers.map((teacher, index) => (
                                                <Link key={index} to={`/staff/${teacher.id}`}>
                                                    <li className="flex">
                                                        {renderUserImage(teacher.user_id)}
                                                        <div className="ml-3">
                                                            <p className="text-sm font-medium text-gray-900 py-2">
                                                                {teacher.full_name}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </Link>
                                            ))}

                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Kernfach</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {course.major && <span>Ja</span>}
                                            {!course.major && <span>Nein</span>}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Unterrichtet in</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{course.taught_in}
                                        </dd>
                                    </div>

                                    {course.course_number.startsWith('K') && <>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Thema 1. Halbjahr</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{course.course_topic_hy_1}
                                            <EditButton iconOnly={true} link={`/courses/${id}/edit`}/>
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Thema 2. Halbjahr</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {course.course_topic_hy_2}
                                            <EditButton iconOnly={true} link={`/courses/${id}/edit`}/>
                                        </dd>
                                    </div>

                                    </>}

                                </dl>
                            </div>
                        </div>
                    </section>


                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                        {/* Kursbeleherungen */}
                        {materialsAndHomework && <section aria-labelledby="notes-title">
                            <CourseInstructions
                                course={course}
                                courseId={id}
                                courseInstructions={materialsAndHomework.course_instructions}
                                permissions={permissions}
                            />
                        </section>}
                    </div>


                </div>

                <section
                    aria-labelledby="timeline-title"
                    className="lg:col-start-3 lg:col-span-1"
                >
                    {course.students && canViewStudentsMini && (
                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        SchülerInnen
                                    </h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {course.students.map((student, index) => (
                                            <Link key={index} to={`/school/students/${student.id}`}>
                                                <li className="sm:px-6 flex py-4">
                                                    {renderUserImage(student.user_id)}
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-gray-900 py-2">
                                                            {student.full_name}
                                                        </p>
                                                    </div>
                                                    {renderRating(student.current_plupu_rating)}
                                                </li>
                                            </Link>
                                        ))}
                                    </ul>

                                    {canViewStudentsBasic && <div className="px-4 py-5 sm:px-6">
                                        {course && <DownloadButton
                                            apiFunction={() => getStudentListExcel(id)}
                                            fileName={`Schüler ${course.course_number}.xls`}
                                            label="Schülerliste herunterladen"/>}
                                    </div>}

                                </div>
                            </div>
                        </section>
                    )}
                </section>

            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                    {/* Kursmaterialien */}
                    {materialsAndHomework && <section aria-labelledby="notes-title">
                        <CourseMaterials
                            course={course}
                            courseId={id}
                            courseMaterials={materialsAndHomework.course_materials}
                            permissions={permissions}
                        />
                    </section>}
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                    {/* Hausaufgabenmaterialien */}
                    {materialsAndHomework && <section aria-labelledby="notes-title">
                        <HomeworkMaterials
                            courseId={id}
                            homeworkMaterials={materialsAndHomework.homework_materials}
                            permissions={permissions}
                        />
                    </section>}
                </div>
            </div>

        </DetailPage>


    );
}


function CourseMaterials({courseMaterials, permissions, courseId, course}) {

    const columns = [
        {
            accessorKey: "get_file_name",
            id: "get_file_name",
            header: "Datei",
            cell: ({row, value}) => (

                row.original.course_material_files.length > 0 ?
                    row.original.course_material_files.map((f, index) => {
                        return <TwoLineColumnWithLink key={index}
                                                      link={`${f.file}`}
                                                      line1={`${row.original.title}`}
                                                      line2={`${f.get_file_name}`}
                        />
                    }) : <TwoLineColumn line1={`${row.original.title}`} />
            ),
            enableColumnFilter: false,
            classNames: "max-w-xs text-gray-500",
            // headerClassNames: "max-w-md",
        },
        {
            accessorKey: "creation_date",
            id: "creation_date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "created_by_full_name",
            id: "created_by_full_name",
            header: "Erstellt von",
            // cell: (info) => <UserName link={true} userId={info.getValue()} />,
            enableColumnFilter: false,
        },
        {
            accessorKey: "description",
            id: "description",
            header: "Beschreibung",
            enableColumnFilter: false,
            classNames: "max-w-md",
        },

    ];

    if (course && course.can_create_course_material) {
        columns.push({
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <>
                    <EditButton iconOnly={true} link={`/courses/course-material/${row.original.id}/edit`}/>
                </>
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
        })
    }

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Kursmaterialien
                    </h2>

                    {course && course.can_create_course_material &&
                        <PrimaryButton link={`/courses/${courseId}/course-material/new`}
                                       label="Kursmaterial einstellen"/>}
                </div>
                <div className="">
                    {courseMaterials && (
                        <ListViewTable
                            data={courseMaterials}
                            columns={columns}
                            disableGlobalSearch={true}
                            getRowLink={null}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}


function CourseInstructions({courseInstructions, permissions, courseId, course}) {

    let columns = [
        {
            accessorKey: "creation_date",
            id: "creation_date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "created_by_full_name",
            id: "created_by_full_name",
            header: "Erstellt von",
            // cell: (info) => <UserName link={true} userId={info.getValue()} />,
            enableColumnFilter: false,
        },
        {
            accessorKey: "description",
            id: "description",
            header: "Beschreibung",
            enableColumnFilter: false,
            classNames: "max-w-md",
        },
        {
            accessorKey: "get_file_name",
            id: "get_file_name",
            header: "Download",
            cell: ({row, value}) => (

                row.original.file ?
                    <TwoLineColumnWithLink
                        link={`${row.original.file}`}
                        line1={`${row.original.get_file_name}`}
                    />
                    : ""
            ),
            enableColumnFilter: false,
            classNames: "max-w-xs text-gray-500",
            // headerClassNames: "max-w-md",
        }
    ];

    if (course && course.can_create_course_instructions) {
        columns.push({
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <>
                    <EditButton iconOnly={true} link={`/courses/course-instructions/${row.original.id}/edit`}/>
                </>
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
        })
    }

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Kursbelehrungen
                    </h2>
                    {course && course.can_create_course_instructions &&
                        <PrimaryButton link={`/courses/${courseId}/course-instructions/new`}
                                       label="Neue Kursbelehrung"/>}

                </div>
                <div className="">
                    {courseInstructions && (
                        <ListViewTable
                            data={courseInstructions}
                            columns={columns}
                            disableGlobalSearch={true}
                            getRowLink={null}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}


function HomeworkMaterials({homeworkMaterials, permissions}) {

    const columns = [
        {
            accessorKey: "get_file_name",
            id: "get_file_name",
            header: "Titel",
            cell: ({row, value}) => (

                row.original.file ? <TwoLineColumnWithLink
                    link={`${row.original.file}`}
                    line1={`${row.original.title}`}
                    line2={`${row.original.get_file_name}`}
                /> : ""
            ),
            enableColumnFilter: false,
            classNames: "max-w-xs text-gray-500",
            // headerClassNames: "max-w-md",
        },
        {
            accessorKey: "creation_date",
            id: "creation_date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "created_by_full_name",
            id: "created_by_full_name",
            header: "Erstellt von",
            // cell: (info) => <UserName link={true} userId={info.getValue()} />,
            enableColumnFilter: false,
        },
        {
            accessorKey: "description",
            id: "description",
            header: "Beschreibung",
            enableColumnFilter: false,
            classNames: "max-w-md",
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <>
                    <EditButton iconOnly={true} link={`/class-register/homework/${row.original.homework_id}/edit`}/>
                </>
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
        }
    ];

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Hausaufgabenmaterialien
                    </h2>
                </div>
                <div className="">
                    {homeworkMaterials && (
                        <ListViewTable
                            data={homeworkMaterials}
                            columns={columns}
                            disableGlobalSearch={true}
                            getRowLink={null}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}