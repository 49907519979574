import React, {useState} from "react";
import Logo from "../../layout/Logo";
import {Link, useParams} from 'react-router-dom'
import Alert from '../../elements/Alert'
import {useAuth} from "../../../contexts/AuthContext"

import http from "../../../services/httpService";

import Joi from "joi"

import {passwordErrorMessage, passwordRegEx} from "../../../utils/regularExpressions";
import {validate, validateProperty} from "../../../utils/validation";
import {Input} from "../../elements/forms/Inputs"
import {useQueryClient} from "@tanstack/react-query";

// TODO check and adjust
// API call too
export default function ChangePassword() {

    const [data, setData] = useState({
        old_password: null,
        password: null,
        confirm_password: null
    })

    const [errors, setErrors] = useState({}) // validation errors
    const [success, setSuccess] = useState("") // overall success
    const [error, setError] = useState("") // API error
    const [loading, setLoading] = useState(false) // loading to prevent double submits

    const {currentUser} = useAuth()
    const queryClient = useQueryClient()

    const schema = {
        old_password: Joi.any().when('password', {
            is: Joi.string().required().min(1),
            then: Joi.string().required(),
            otherwise: Joi.optional(),
        }).messages({"any.only": "Bitte gib dein altes Passwort ein"}),
        password: Joi.string().regex(passwordRegEx).allow(null, '').label("Passwort")
            .messages({"string.pattern.base": passwordErrorMessage}),
        confirm_password: Joi.any()
            .valid(Joi.ref('password')).label("Passwort wiederholen")
            .messages({"any.only": "Die Passwörter stimmen nicht überein"})
    };

    function handleChange(evt) {
        const value = evt.target.value;

        setData({
            ...data,
            [evt.target.name]: value
        });

        // field validation
        setErrors({
            ...errors,
            [evt.target.name]: validateProperty(evt.target, schema, data)
        });

    }

    async function handleSubmit(e) {

        e.preventDefault()
        setLoading(true)

        // validation of the entire form/schema
        const validationErrors = validate(schema, data)
        setErrors(validationErrors)
        if (validationErrors) {
            setError("Bitte korrigiere die Felder wie unten angegeben.")
            setLoading(false)
            return
        } else {
            setError("")
        }

        try {
            setSuccess("")
            let sendData = data;
            if (!sendData.password) delete sendData.password; // so we can update other fields as well
            await http.patch("/users/" + currentUser.user_id + "/", sendData);
            queryClient.invalidateQueries({ queryKey: ['self_info', currentUser.user_id] })
            setSuccess("Dein Passwort wurde geändert.")
        } catch (e) {
            // TODO better error handling
            // console.log(e.response.request.response)
            setError("Dein Passwort konnte nicht geändert werden. Versuche es erneut oder wende dich an das Leitungsteam.")
        }

        setLoading(false)

    }

    return (
        <form id="passwordResetForm" onSubmit={handleSubmit}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Passwort ändern</h3>
                        {/*<p className="mt-1 text-sm text-gray-500">*/}
                        {/*    This information will be displayed publicly so be careful what you share.*/}
                        {/*</p>*/}
                    </div>

                    <div className="grid grid-cols-3 gap-6">

                        {error && <Alert variant="danger">{error}</Alert>}
                        {success && <Alert variant="success">{success}</Alert>}

                        <Input
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                            label="Altes Passwort"
                            path="old_password"
                            type="password"
                            className="sm:col-span-3"
                            required
                        />

                        <Input
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                            label="Neues Passwort"
                            path="password"
                            type="password"
                            className="sm:col-span-3"
                            required
                        />

                        <Input
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                            label="Neues Passwort wiederholen"
                            path="confirm_password"
                            type="password"
                            className="sm:col-span-3"
                            required
                        />

                    </div>

                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                    <button
                        disabled={loading}
                        type="submit"
                        className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"
                    >
                        Passwort speichern
                    </button>

                </div>
            </div>

        </form>
    )
}
